import * as events from "../Events";

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      console.log(email, password);

      const response = await fetch(`${events.API_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      console.log(email, password);
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const user = resData.data;

        dispatch({ type: events.SET_USER, data: user });
        localStorage.setItem("auth", user.token);
        localStorage.setItem("firstName", user.firstname);
        localStorage.setItem("lastName", user.lastname);
        localStorage.setItem("email", user.email);
        localStorage.setItem("id", user._id);
        localStorage.setItem("time", 10);
        window.location.reload(false);
        return user ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};

export const register = (firstname, lastname, email, password) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
          // invitation_code: inviteCode,
        }),
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const user = resData.data;
        dispatch({ type: events.REGISTER, data: user });

        return user ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
export const forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/auth/forgot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError.error);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const user = resData.data;
        dispatch({ type: events.FORGOT_PASSWORD, data: user });
        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const resetPassword = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/auth/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError.error);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const user = resData.data;
        dispatch({ type: events.RESET_PASSWORD, data: user });
        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const updateProfile = (data) => {
  console.log(data);
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/upload-img`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const updatedProfile = resData.data;

        dispatch({ type: events.UPDATE_PROFILE, data: updatedProfile });
        return updatedProfile ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
export const getProfile = () => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const profile = resData.data;

        dispatch({ type: events.GET_PROFILE, data: profile });
        return profile ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
export const generateNewPassword = (data) => {
  const token = localStorage.getItem("auth");
  const id = localStorage.getItem("id");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/users/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const profile = resData.data;

        dispatch({ type: events.GET_PROFILE, data: profile });
        return profile ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
export const logout = () => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const profile = resData.data;

        dispatch({ type: events.GET_PROFILE, data: profile });
        return profile ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
