import {
  Box,
  Flex,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
  Tooltip,
} from "native-base";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { aw, ah } from "../utils";

const LeadsWatchSideBar = () => {
  const profile = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HStack zIndex={1}>
      <Box
        pt={aw(5)}
        style={[styles.defaultopen, styles.styleopen]}
        borderRightWidth={ah(1)}
        borderRightColor={"#E6E7E9"}
      >
        <VStack
          //   justifyContent={"space-evenly"}
          space={ah(20)}
          alignItems={"center"}
          //   height={ah(362)}
        >
          <Pressable onPress={() => navigate("/")}>
            {({ isFocused, isHovered }) => {
              return (
                <Flex>
                  <HStack
                    pl={aw(10)}
                    alignItems={"center"}
                    // justifyContent={"center"}
                    style={
                      isHovered || location.pathname === "/"
                        ? styles.styleStack
                        : styles.styleStackOff
                    }
                  >
                    <Text
                      fontFamily={"RobotoMedium"}
                      color="#121233"
                      style={
                        isHovered || location.pathname === "/"
                          ? styles.defaultText
                          : styles.defaultTextoff
                      }
                    >
                      Activity
                    </Text>
                  </HStack>
                </Flex>
              );
            }}
          </Pressable>
          <Pressable onPress={() => navigate("/subscription")}>
            {({ isFocused, isHovered }) => {
              return (
                <Flex>
                  <HStack
                    pl={aw(10)}
                    alignItems={"center"}
                    // justifyContent={"center"}
                    style={
                      isHovered ||
                      location.pathname === "/subscription" ||
                      location.pathname.includes("subscription")
                        ? styles.styleStack
                        : styles.styleStackOff
                    }
                  >
                    <Text
                      color="#121233"
                      fontFamily={"RobotoMedium"}
                      style={
                        isHovered ||
                        location.pathname === "/subscription" ||
                        location.pathname.includes("subscription")
                          ? styles.defaultText
                          : styles.defaultTextoff
                      }
                    >
                      Subscription
                    </Text>
                  </HStack>
                </Flex>
              );
            }}
          </Pressable>
          <Pressable onPress={() => navigate("/registers")}>
            {({ isFocused, isHovered }) => {
              return (
                <Flex>
                  <HStack
                    pl={aw(10)}
                    alignItems={"center"}
                    // justifyContent={"center"}
                    style={
                      isHovered ||
                      location.pathname === "/registers" ||
                      location.pathname.includes("registers")
                        ? styles.styleStack
                        : styles.styleStackOff
                    }
                  >
                    <Text
                      color="#121233"
                      fontFamily={"RobotoMedium"}
                      style={
                        isHovered ||
                        location.pathname === "/registers" ||
                        location.pathname.includes("registers")
                          ? styles.defaultText
                          : styles.defaultTextoff
                      }
                    >
                      Registers
                    </Text>
                  </HStack>
                </Flex>
              );
            }}
          </Pressable>
          <Pressable onPress={() => navigate("/details")}>
            {({ isFocused, isHovered }) => {
              return (
                <Flex>
                  <HStack
                    pl={aw(10)}
                    alignItems={"center"}
                    // justifyContent={"center"}
                    style={
                      isHovered ||
                      location.pathname === "/details" ||
                      location.pathname.includes("details")
                        ? styles.styleStack
                        : styles.styleStackOff
                    }
                  >
                    <Text
                      color="#121233"
                      fontFamily={"RobotoMedium"}
                      style={
                        isHovered ||
                        location.pathname === "/details" ||
                        location.pathname.includes("details")
                          ? styles.defaultText
                          : styles.defaultTextoff
                      }
                    >
                      Details
                    </Text>
                  </HStack>
                </Flex>
              );
            }}
          </Pressable>
        </VStack>
      </Box>
    </HStack>
  );
};

const styles = {
  default: {
    width: aw(68),
    height: ah(698),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    shadowColor: "#0000001A",
    shadowOffset: { width: "0px", height: "3px" },
    shadowRadius: "15px",
  },

  defaultopen: {
    width: aw(168),
    height: ah(698),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // shadowColor: "#0000001A",
    // shadowOffset: { width: "0px", height: "3px" },
    // shadowRadius: "15px",
  },
  defaultText: {
    fontSize: aw(14),
    color: "#2230F8",
  },
  defaultTextoff: {
    fontSize: aw(14),
  },
  styleStack: {
    width: aw(148),
    height: ah(48),
    borderRadius: aw(10),
    background: " #F3F3FF 0% 0% no-repeat padding-box",
  },
  styleStackOff: {
    width: aw(148),
    height: ah(48),
    borderRadius: aw(10),
    background: " #FFFFFF 0% 0% no-repeat padding-box",
  },
};

export default LeadsWatchSideBar;
