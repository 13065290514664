// uri

import Users from "../screens/details/Users";

// export const API_URL = "http://192.168.1.110:3005/v2/referrals";
export const API_URL = "https://api.shadow.properties/v2/referrals";
export const API_URL2 = "https://api.shadow.properties/v2";

//auth events
export const SET_USER = "SET_USER";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PROFILE = "GET_PROFILE";

//Details
export const GET_ALL_DETAILS = "GET_ALL_DETAILS";
export const ADD_NEW_DETAILS = "ADD_NEW_DETAILS";
export const EDIT_DETAILS = "EDIT_DETAILS";
export const GET_SINGLE_DETAILS = "GET_SINGLE_DETAILS";

//Activity
export const GET_ALL_ACTIVITY = "GET_ALL_ACTIVITY";

//Registers
export const GET_ALL_REGISTERS = "GET_ALL_REGISTERS";

//Users
export const GET_ALL_USERS = "GET_ALL_USERS";
