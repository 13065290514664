import { VStack, Text, NativeBaseProvider, HStack } from "native-base";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";

import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";

import Activity from "./screens/dashboard/Activity";
import LeadsWatchTopBar from "./components/LeadsWatchTopBar";
import LeadsWatchSideBar from "./components/LeadsWatchSideBar";
import Subscription from "./screens/subscription/Subscription";
import Registers from "./screens/registers/Registers";
import Users from "./screens/details/Users";
import UserDetails from "./screens/details/UserDetails";

const App = () => {
  const token = localStorage.getItem("auth");
  return token ? (
    <Router>
      <LeadsWatchTopBar />
      <HStack>
        <LeadsWatchSideBar />

        <Routes>
          <Route path="/" element={<Activity />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/registers" element={<Registers />} />
          <Route path="/details" element={<Users />} />
          <Route path="details/userDetails/:id" element={<UserDetails />} />
        </Routes>
      </HStack>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
